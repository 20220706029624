import React from "react";
import TextField from '@mui/material/TextField'
const Input = ({ name, label,error, ...rest}) => {
  return (
    // <div className="form-group">
    //   <label htmlFor={name}>{label}</label>
    //   <input {...rest} name={name} id={name} className="form-control" />
    //   {error && <div className="alert alert-danger"> {error}</div>}
    // </div>
    <div className='my-2'>
      <TextField
          error={error}
          id={name}
          name={name}
          label={label}
          {...rest}
          // defaultValue="Hello World"
          helperText={error &&  `${error}`}
          // variant="filled"
        />
    </div>
  );
};

export default Input;

